import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

// Constants for file types and extensions
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const CSV_TYPE = 'text/csv;charset=utf-8;';
const CSV_EXTENSION = '.csv';

@Injectable({
  providedIn: 'root'
})
export class ExcelServicesService {
  constructor() { }

  // Export JSON data as an Excel file
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsFile(excelBuffer, excelFileName, EXCEL_TYPE, EXCEL_EXTENSION);
  }

  // Export JSON data as a CSV file
  public exportAsCsvFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const csvBuffer: any = XLSX.write(workbook, { bookType: 'csv', type: 'array' });
    this.saveAsFile(csvBuffer, excelFileName, CSV_TYPE, CSV_EXTENSION);
  }

  // Save the buffer as a file (Excel or CSV)
  private saveAsFile(buffer: any, fileName: string, fileType: string, fileExtension: string): void {
    const data: Blob = new Blob([buffer], { type: fileType });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + fileExtension);
  }
}
